// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import LoadingWheelSvg from "../../svg/loading-wheel.svg";
import "./loadingWheel.scss";

type Props = {
  cypress?: string | null;
  size?: "tiny" | "small" | "medium" | "large";
};
const LoadingWheel = ({ cypress = null, size }: Props) => {
  const baseClass = "c-loading-wheel";
  return (
    <LoadingWheelSvg
      className={`${bem(baseClass, null, {
        size
      })}`}
      data-cy={cypress}
      role="presentation"
    />
  );
};

export default LoadingWheel;
