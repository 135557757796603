// @ts-nocheck
import React, { Node } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Typography from "../Typography";
import "./heading.scss";

type Props = {
  bold?: boolean;
  className?: string;
  color?: "primary" | "light" | "brand" | "muted" | "error";
  level: 1 | 2 | 3;
  children: Node;
};
const getTagName = (level) => {
  switch (level) {
    case 2:
      return "h2";
    case 3:
      return "h3";
    default:
      return "h1";
  }
};
const Heading = ({
  children,
  bold = false,
  className = "",
  color = "primary",
  level
}: Props) => {
  const baseClass = "c-heading";
  const HeadingTag = getTagName(level);
  return (
    <Typography
      size={HeadingTag}
      bold={bold}
      color={color}
      className={`${bem(baseClass, null, {
        [HeadingTag]: true
      })} ${className}`}
    >
      <HeadingTag>{children}</HeadingTag>
    </Typography>
  );
};

export default Heading;
